import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../../axios';
import { PublicSource } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';

type ResponseType = {
  sources: PublicSource[];
};

type ReturnType = {
  isLoading: boolean;
  isError: boolean;
  sources: PublicSource[];
};

const QUERY_KEY = 'public_sources';
const fetchPublicSources = () =>
  axiosInstance.get<ResponseType>('/needlbox/public_sources');

export const useFetchPublicSources = (): ReturnType => {
  const {
    permissions: { canViewNeedlBox },
  } = useGetPermissions();

  const {
    isLoading,
    isError,
    data: sources,
  } = useQuery([QUERY_KEY], fetchPublicSources, {
    enabled: canViewNeedlBox,
    select: (data) => {
      return data.data.sources;
    },
  });

  return { isLoading, isError, sources };
};

export const useInvalidateFetchPublicSources = (): (() => void) => {
  const queryClient = useQueryClient();
  return React.useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY]);
  }, [queryClient]);
};
