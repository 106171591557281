import { getGridNumericColumnOperators, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

import { PermissionsType } from '../../utils/types';
import DropdownCell from '../common/DropDownCell';
import MaskCell from '../common/MaskCell';
import NavigationCell from '../common/NavigationCell';

const booleanCellOptions = ['true', 'false'];

export const getColumns = ({
  canUpdateOrganization,
}: Pick<PermissionsType, 'canUpdateOrganization'>): GridColDef[] => [
  {
    headerName: 'ID',
    field: 'id',
    hide: true,
  },
  {
    headerName: 'Name',
    field: 'organization_name',
    width: 200,
    renderCell: ({ value }) => <NavigationCell value={value} />,
  },
  {
    headerName: 'Domain',
    field: 'organization_domain',
    width: 200,
    valueGetter: ({ value }) => value || 'None',
  },
  {
    headerName: 'Total Users',
    field: 'total_users',
    width: 160,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => value || 0,
    renderCell: ({ value }) => <NavigationCell value={value} />,
  },
  {
    headerName: 'Total Admins',
    field: 'total_admin_users',
    width: 170,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => value || 0,
    renderCell: ({ value }) => <NavigationCell value={value} />,
  },
  {
    headerName: 'Allow other domain users',
    field: 'allow_other_domain_users',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'Auto add domain users',
    field: 'auto_add_domain_users',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'Allow sharing outside organization',
    field: 'allow_sharing_outside_org',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'API Access Key',
    field: 'api_access_key',
    width: 400,
    renderCell: ({ value }) => <MaskCell value={value} />,
  },
];
