import type { OrganizationInfo } from '../Users/useFetchOrganization';
import type { SettingsType } from './ConfigurationProvider';
import type { FeatureSettings } from './hooks/useFetchOrganizationFeatures';

export const configurationReducer = (
  state: SettingsType,
  action: ConfigurationReducerActions
) => {
  switch (action.type) {
    case 'SET_FEATURES':
      return {
        ...state,
        features: state.features
          ? {
              ...state.features,
              ...action.payload.features,
            }
          : action.payload.features,
      };
    case 'SET_ORGANIZATION_INFO':
      return {
        ...state,
        info: action.payload.info,
      };
    case 'TOGGLE_FEATURE':
      return {
        ...state,
        features: {
          ...state.features,
          [action.payload.featureId]: !state.features[action.payload.featureId],
        },
      };
    case 'TOGGLE_INFO_SETTINGS':
      return {
        ...state,
        info: {
          ...state.info,
          [action.payload.settingId]: !state.info[action.payload.settingId],
        },
      };
    default:
      return state;
  }
};

export type ConfigurationReducerActions =
  | {
      type: 'SET_FEATURES';
      payload: { features: FeatureSettings };
    }
  | {
      type: 'SET_ORGANIZATION_INFO';
      payload: { info: OrganizationInfo };
    }
  | {
      type: 'TOGGLE_FEATURE';
      payload: { featureId: string };
    }
  | {
      type: 'TOGGLE_INFO_SETTINGS';
      payload: {
        settingId: keyof Pick<
          OrganizationInfo,
          | 'allow_other_domain_users'
          | 'allow_sharing_outside_org'
          | 'auto_add_domain_users'
        >;
      };
    };
