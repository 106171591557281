import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

import { axiosInstance } from '../../axios';

type Response = {
  url: string;
};

type PayloadData = {
  uploadUrl: string;
  file: File;
};

export const useUploadFile = (): UseMutationResult => {
  return useMutation(({ uploadUrl, file }: PayloadData) => {
    return axiosInstance.put<Response>(uploadUrl, file, {
      headers: { 'Content-Type': file.type },
    });
  });
};
