import {
  Box,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import UnionIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useFetchPricingLimits,
  useInvalidateFetchPricingLimits,
} from '../../hooks/useFetchPricingLimits';
import { useTableExport } from '../../hooks/useTableExport';
import { useTableSearch } from '../../hooks/useTableSearch';
import {
  COMMON_ORGANIZATION_IDS,
  DEFAULT_ORGANIZATION_ID,
} from '../../utils/organizationId';
import { Action, UserDetailsType } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';
import { ActionsMenu } from '../common/ActionsMenu';
import { CommonModal, MODAL_TYPE } from '../common/CommonModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import {
  getAlertActionText,
  getAlertDescription,
  getAlertTitle,
} from '../common/messages';
import SearchInput from '../common/SearchInput';
import SelectMenu from '../common/SelectMenu';
import { AddUsersButton } from './AddUsersButton';
import { getColumns } from './columns';
import { useChangeUserAttribute } from './useChangeUserAttribute';
import { useFetchOrganizations } from './useFetchOrganizations';
import { useFetchUsers, useInvalidateFetchUsers } from './useFetchUsers';
import { useRemoveUsersFromOrganization } from './useRemoveUsers';
import { UserListSeatManagement } from './UserListSeatManagement';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteButton: {
    padding: '0 4px 4px 4px',
    marginLeft: '12px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    width: '100%',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));

type RouteParam = { organizationId: string };

const Users = (): JSX.Element => {
  const {
    permissions: {
      canChangeUserPlan,
      canViewUserActivityLog,
      canChangeUserRole,
      canViewAllOrganizations,
      canAddUsersToOrganization,
      canRemoveUsersFromOrganization,
    },
  } = useGetPermissions();
  const { organizationId } = useParams<RouteParam>();
  const { isLoading, users, options } = useFetchUsers(organizationId);
  const { organizations } = useFetchOrganizations();
  const invalidateFetchUsers = useInvalidateFetchUsers(organizationId);
  const invalidatePricingLimits = useInvalidateFetchPricingLimits();

  const columns = React.useMemo(
    () =>
      getColumns({
        options,
        canChangeUserPlan,
        canChangeUserRole:
          canChangeUserRole &&
          Number(organizationId) !== DEFAULT_ORGANIZATION_ID,
      }),
    [canChangeUserPlan, canChangeUserRole, options, organizationId]
  );

  const [rows, setRows] = React.useState(users);
  const [showDialog, setShowDialog] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [results, setResults] = React.useState<Record<string, any>[]>();
  const [isButtonLoading, setButtonLoading] = React.useState(false);
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<Action>({ type: 'null' });

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const { searchValue, handleSearch } = useTableSearch({
    data: users,
    setTableRows: (filteredRows) => setRows(filteredRows as UserDetailsType[]),
  });

  const dataToExport = React.useMemo(
    () =>
      selectedUserIds.length
        ? rows.filter(({ id }) => selectedUserIds.includes(id))
        : rows,
    [rows, selectedUserIds]
  );

  const downloadExcel = useTableExport({ data: dataToExport, type: 'xlsx' });
  const downloadCSV = useTableExport({ data: dataToExport, type: 'csv' });

  const changeUserPlan = useChangeUserAttribute('plan');
  const changeUserRole = useChangeUserAttribute('role');
  const removeUsers = useRemoveUsersFromOrganization();

  const history = useHistory();
  const classes = useStyles();
  const { data: priceLimits, isLoading: isPriceLimitsLoading } =
    useFetchPricingLimits();

  React.useEffect(() => {
    setRows(users);
  }, [users]);

  const closeDialog = () => {
    setShowDialog(false);
    setError('');
    setResults([]);
  };

  const mutate = (action: Action) => {
    const { type, payload } = action;
    switch (type) {
      case 'plan': {
        return changeUserPlan({
          organizationId,
          public_uuid: payload.id,
          value: payload.value,
        });
      }
      case 'role': {
        return changeUserRole({
          organizationId,
          public_uuid: payload.id,
          value: payload.value,
        });
      }
      case 'removeUsers': {
        return removeUsers({
          organizationId,
          user_ids: payload.value,
        });
      }
    }
  };

  const actionHandler = async () => {
    try {
      setButtonLoading(true);
      const { data } = await mutate(action);
      handleSearch('');
      setSelectedUserIds([]);
      invalidateFetchUsers();
      invalidatePricingLimits();
      if (data?.results) setResults(data?.results);
      else closeDialog();
    } catch (e) {
      const errorMsg =
        e?.response?.data?.error || 'Something went wrong! Please try later';
      setError(errorMsg);
    } finally {
      setButtonLoading(false);
    }
  };

  const cellValueChangeHandler = ({ field, value, id }: any) => {
    setRows([...rows]);
    if (['plan', 'role'].includes(field)) {
      setShowDialog(true);
      setAction({
        type: field,
        payload: { id, value },
      });
    }
  };

  const removeUsersHandler = () => {
    setShowDialog(true);
    setAction({
      type: 'removeUsers',
      payload: { value: selectedUserIds },
    });
  };

  const cellClickHandler = ({ field, id }: any) => {
    if (field === 'email' && canViewUserActivityLog) {
      history.push(`/organizations/${organizationId}/users/${id}`);
    }
  };

  const changeOrganizationHandler = (organizationId: number) => {
    history.push(`/organizations/${organizationId}/users`);
  };

  return (
    <>
      <Box className={classes.rootContainer}>
        <Box className={classes.buttonContainer}>
          <Typography variant='h4'>
            List of Users
            <UserListSeatManagement />
          </Typography>
          <Box display='flex' alignItems='flex-end'>
            {canViewAllOrganizations && organizations.length > 0 && (
              <SelectMenu
                label='Organization'
                selectedOptionId={parseInt(organizationId, 10)}
                options={organizations.map(({ id, organization_name }) => ({
                  id,
                  label: organization_name,
                }))}
                changeHandler={changeOrganizationHandler}
              />
            )}
            <SearchInput value={searchValue} handleSearch={handleSearch} />
            {canAddUsersToOrganization &&
              !COMMON_ORGANIZATION_IDS.includes(Number(organizationId)) && (
                <AddUsersButton handleClick={handleModalOpen} />
              )}
            {canRemoveUsersFromOrganization &&
              !COMMON_ORGANIZATION_IDS.includes(Number(organizationId)) && (
                <IconButton
                  aria-label='delete'
                  color='primary'
                  className={classes.deleteButton}
                  onClick={removeUsersHandler}
                  disabled={!selectedUserIds.length}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            <ActionsMenu
              items={[
                { label: 'Export as xlsx', action: downloadExcel },
                { label: 'Export as csv', action: downloadCSV },
              ]}
            />
          </Box>
        </Box>
        {!isPriceLimitsLoading &&
          priceLimits?.seats_used === priceLimits?.seats_limit && (
            <Box display='flex' flexDirection='row' marginTop='14px'>
              <Box paddingRight='8px'>
                <UnionIcon />
              </Box>
              <Typography>
                Your <b>Teams</b> plan is at its maximum number of members
                allowed.
                <Link
                  href={`${process.env.REACT_APP_MAIN_URL}/settings/user-settings`}
                  color='#006D77'
                  underline='always'
                >
                  &nbsp;Upgrade your plan and add more members.
                </Link>
              </Typography>
            </Box>
          )}
        <Box className={classes.tableContainer}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              autoPageSize
              disableSelectionOnClick
              isCellEditable={() => true}
              onCellEditCommit={cellValueChangeHandler}
              onCellClick={cellClickHandler}
              checkboxSelection
              onSelectionModelChange={(selectedUserIds) => {
                setSelectedUserIds(selectedUserIds as string[]);
              }}
            />
          )}
        </Box>
        <ConfirmationDialog
          show={showDialog}
          closeDialog={closeDialog}
          actionHandler={actionHandler}
          errorMessage={error}
          results={results}
          isLoading={isButtonLoading}
          title={getAlertTitle(action)}
          description={getAlertDescription(action)}
          primaryButtonText={getAlertActionText(action)}
        />
        <CommonModal
          modalType={MODAL_TYPE.ADD_USERS}
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />
      </Box>
    </>
  );
};

export default Users;
