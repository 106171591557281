import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useConfigurationContext } from './ConfigurationProvider';
import { FeatureGroupToggle } from './FeatureGroupToggle';
import { FeatureToggle } from './FeatureToggle';
import { useFetchFeaturesConfig } from './hooks/useFetchFeaturesConfig';
import { useFetchOrganizationFeatures } from './hooks/useFetchOrganizationFeatures';

const useStyles = makeStyles(() => ({
  togglesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

type RouteParam = { organizationId: string };

export const OrganizationFeatures = () => {
  const classes = useStyles();
  const { organizationId } = useParams<RouteParam>();
  const {
    data: config,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useFetchFeaturesConfig();
  const {
    data: organizationFeatureSettings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
  } = useFetchOrganizationFeatures(parseInt(organizationId, 10));
  const { state, dispatch } = useConfigurationContext();

  React.useEffect(() => {
    if (organizationFeatureSettings) {
      dispatch({
        type: 'SET_FEATURES',
        payload: {
          features: organizationFeatureSettings,
        },
      });
    }
  }, [organizationFeatureSettings, dispatch]);

  const isLoading = isConfigLoading;

  if (isConfigError || isSettingsError) {
    toast.error('Error in fetching features data');
  }

  return (
    <Box>
      <Box className={classes.togglesWrapper}>
        <Typography variant='h4'>Configuration</Typography>
        {config && !isLoading ? (
          <>
            {config.map((group) => (
              <FeatureGroupToggle
                key={group.id}
                id={group.id}
                name={group.name}
                description={group.description}
                features={group.features.map((item) => item.id)}
                disabled={isSettingsLoading}
              >
                {group.features.map((feature) => (
                  <FeatureToggle
                    key={feature.id}
                    id={feature.id}
                    name={feature.name}
                    disabled={isSettingsLoading}
                    additionalContent={
                      feature.id === 'group_feeds' && (
                        <FormControlLabel
                          label='Allow sharing outside org'
                          control={
                            <Checkbox
                              size='small'
                              color='primary'
                              checked={
                                state.info
                                  ? state.info.allow_sharing_outside_org
                                  : false
                              }
                              onChange={() =>
                                dispatch({
                                  type: 'TOGGLE_INFO_SETTINGS',
                                  payload: {
                                    settingId: 'allow_sharing_outside_org',
                                  },
                                })
                              }
                            />
                          }
                        />
                      )
                    }
                  />
                ))}
              </FeatureGroupToggle>
            ))}
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};
