import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../../axios';

const QUERY_KEY = 'organization-info';

export const useFetchOrganization = (organizationId: number) => {
  return useQuery(
    [QUERY_KEY, organizationId],
    () => axiosInstance.get<ResponseType>(`/organizations/${organizationId}`),
    {
      enabled: !!organizationId,
      select: (data) => data.data.result,
    }
  );
};

export type ResponseType = {
  result: OrganizationInfo;
};

export type OrganizationInfo = {
  id: number;
  name: string;
  domain: string;
  description: string;
  allow_other_domain_users: boolean;
  auto_add_domain_users: boolean;
  created_at: string;
  allow_sharing_outside_org: boolean;
};

export const useInvalidateFetchOrganization = () => {
  const queryClient = useQueryClient();
  return (organizationId: number) => {
    queryClient.invalidateQueries([QUERY_KEY, organizationId]);
  };
};
