import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useFetchOrganization } from '../Users/useFetchOrganization';
import { useConfigurationContext } from './ConfigurationProvider';

type RouteParam = { organizationId: string };

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: theme.palette.grey[50],
    padding: '24px',
    borderRadius: '16px',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
  },
  infoWrapper: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
  },
}));

export const OrganizationInfo = () => {
  const classes = useStyles();
  const { organizationId } = useParams<RouteParam>();
  const {
    data: organization,
    isLoading,
    isError,
  } = useFetchOrganization(parseInt(organizationId, 10));
  const { state, dispatch } = useConfigurationContext();

  React.useEffect(() => {
    if (organization) {
      dispatch({
        type: 'SET_ORGANIZATION_INFO',
        payload: {
          info: organization,
        },
      });
    }
  }, [organization, dispatch]);

  if (isError) {
    toast.error('Error in fetching organization details');
  }

  return (
    <Box className={classes.mainWrapper}>
      {state.info && !isLoading ? (
        <Box>
          <Box>
            <Typography variant='h5'>Organization Info</Typography>
          </Box>
          <Box className={classes.infoWrapper}>
            <Typography variant='h6'>
              Name: {state.info.name} | Domain: {state.info.domain}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.info.allow_other_domain_users}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_INFO_SETTINGS',
                      payload: { settingId: 'allow_other_domain_users' },
                    })
                  }
                  color='primary'
                />
              }
              label='Allow other domain users'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.info.auto_add_domain_users}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_INFO_SETTINGS',
                      payload: { settingId: 'auto_add_domain_users' },
                    })
                  }
                  color='primary'
                />
              }
              label='Auto add domain users'
            />
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
