import './index.css';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './Components/AuthProvider';
import { worker } from './mocks/browser';
import Pages from './Pages';
import { getTheme } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  worker.start();
}

const authVariable = {
  mandatorySignId: true,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USERPOOLID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USERPOOL_WEB_CLIENT_ID,
  federationTarget: 'COGNITO_USER_POOLS',

  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
    responseType: 'code',
  },
};

const App = (): JSX.Element => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={getTheme()}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <Pages />
            <Toaster
              position='bottom-center'
              reverseOrder={false}
              toastOptions={{
                duration: 5000,
                style: {
                  backgroundColor: '#2c2c2c',
                  color: 'white',
                },
              }}
            />
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;

ReactDOM.render(<App />, document.getElementById('root'));
