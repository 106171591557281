import { useMutation, UseMutationResult } from 'react-query';

import { axiosInstance } from '../../axios';
import { useInvalidateFetchPublicSources } from './useFetchPublicSources';

export type PayloadData = {
  sourceId: string;
  thumbnailLocation: string;
};

export const useUpdatePublicSource = (): UseMutationResult => {
  const invalidateFetchPublicSources = useInvalidateFetchPublicSources();

  return useMutation(
    ({ sourceId, thumbnailLocation }: PayloadData) => {
      return axiosInstance.put(`/needlbox/public_sources/${sourceId}`, {
        thumbnail_location: thumbnailLocation,
      });
    },
    {
      onSettled: () => {
        invalidateFetchPublicSources();
      },
    }
  );
};
