import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@mui/icons-material';
import * as React from 'react';

import { Switch } from '../Atoms/Switch';
import { useConfigurationContext } from './ConfigurationProvider';
import { getFeatureGroupIcons } from './getFeatureGroupIcons';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
    borderRadius: '16px',
  },
  summaryRoot: {
    cursor: 'default',
    borderRadius: '16px 16px 0px 0px',
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    marginRight: '16px',
    gap: '16px',
  },
  summaryContent: {
    flex: '1',
  },
  summaryIcon: {
    height: '100%',
    paddingTop: '8px',
  },
  summarySwitch: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailsContainer: {
    paddingLeft: '48px',
    paddingRight: '36px',
    width: '100%',
  },
  expanded: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const FeatureGroupToggle = ({
  children,
  id,
  name,
  description,
  disabled = false,
  features,
  additionalContent,
}: FeatureGroupToggleType) => {
  const [expand, setExpand] = React.useState(false);
  const classes = useStyles();
  const { state, dispatch } = useConfigurationContext();
  const Icon = getFeatureGroupIcons(id);

  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const setFeaturesInsideGroup = (value: boolean) => {
    const settingsPaylod = Object.fromEntries(
      features.map((featureId) => [featureId, value])
    );
    dispatch({
      type: 'SET_FEATURES',
      payload: { features: settingsPaylod },
    });
  };
  return (
    <Box>
      <Accordion
        expanded={expand}
        classes={{ root: classes.accordionRoot }}
        square
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDown style={{ pointerEvents: 'auto' }} />}
          onClick={toggleAcordion}
          classes={{ root: classes.summaryRoot, expanded: classes.expanded }}
        >
          <Box style={{ width: '100%' }}>
            <Box className={classes.summaryContainer}>
              <Box className={classes.summaryIcon}>
                <Icon width={24} height={24} />
              </Box>
              <Box className={classes.summaryContent}>
                <Box>
                  <Typography variant='subtitle1'>{name}</Typography>
                </Box>
                <Box>
                  <Typography variant='body2'>{description}</Typography>
                </Box>
              </Box>
              <Box
                className={classes.summarySwitch}
                style={{ pointerEvents: 'auto' }}
              >
                <Switch
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setExpand(e.target.checked);
                    }
                    setFeaturesInsideGroup(e.target.checked);
                  }}
                  disabled={disabled}
                  checked={features.some((featureId) =>
                    state.features ? state.features[featureId] : false
                  )}
                />
              </Box>
            </Box>
            {additionalContent ? (
              <Box style={{ paddingLeft: '40px' }}>{additionalContent}</Box>
            ) : null}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.detailsContainer}>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

type FeatureGroupToggleType = {
  children: React.ReactNode;
  name: string;
  id: string;
  description: string;
  disabled?: boolean;
  features: string[];
  additionalContent?: React.ReactNode;
};
