import { AxiosResponse } from 'axios';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
} from 'react-query';

import { axiosInstance } from '../axios';
import { useLogout } from '../hooks/useLogout';
import { PermissionsType } from './types';

type ResponseType = {
  result: {
    permissions: PermissionsType;
    organizationId: number;
    role: string;
  };
};

type UseGetPermissionType = {
  enabled: boolean;
};

const QUERY_KEY = 'permissions';
const fetchPermissions = () => axiosInstance.get<ResponseType>('/permissions');

export const useGetPermissions = (options?: UseGetPermissionType) => {
  const logoutUser = useLogout();
  const { enabled } = options || { enabled: true };
  const { data, isLoading, isError, refetch } = useQuery(
    [QUERY_KEY],
    fetchPermissions,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      enabled,
      onError: async ({ response }) => {
        const errorMesage =
          response?.data?.error || 'Something went wrong. Please try again';
        await logoutUser(errorMesage);
      },
    }
  );
  const result = data?.data.result;

  return {
    isLoading,
    isError,
    permissions: result?.permissions,
    organizationId: result?.organizationId,
    role: result?.role,
    refetch,
  };
};

export const useRemovePermissions = (): (() => void) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.removeQueries([QUERY_KEY]);
  };
};
