import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as React from 'react';

import { ReactChildrenType } from '../../../utils/types';
import { FullPageLazyLoadingFallback } from '../../Loaders/FullPageLazyLoadingFallback';
import { getAuthConfigAzure } from './config';

let msalInstance: PublicClientApplication;

const AuthProviderAzure: React.FunctionComponent<ReactChildrenType> = ({
  children,
}) => {
  const [isAuthInitialized, setIsAuthInitialized] = React.useState(false);

  React.useEffect(() => {
    const initAuth = async () => {
      if (process.env.REACT_APP_CLOUD_PROVIDER === 'azure') {
        msalInstance = new PublicClientApplication(getAuthConfigAzure());
        await msalInstance.handleRedirectPromise();
      }

      setIsAuthInitialized(true);
    };

    initAuth();
  }, []);

  if (!isAuthInitialized) {
    return <FullPageLazyLoadingFallback />;
  }
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

const loginUserAzure = async () => {
  const loginRequest = {
    scopes: ['user.read'],
  };
  await msalInstance.loginRedirect(loginRequest);
};

const getIdTokenAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const { idToken } = await msalInstance.acquireTokenSilent({
        account: accounts[0],
        scopes: ['user.read'],
      });
      return idToken;
    } catch (e) {
      if (e.name === 'InteractionRequiredAuthError') {
        const { idToken } = await msalInstance.acquireTokenPopup({
          account: accounts[0],
          scopes: ['user.read'],
        });
        return idToken;
      } else {
        throw e;
      }
    }
  }
};

const isAuthenticatedAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  return accounts.length > 0;
};

const signOutAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    await msalInstance.logoutRedirect({
      account: accounts[0],
    });
  }
};

const forceRefreshTokensAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      await msalInstance.acquireTokenSilent({
        account: accounts[0],
        scopes: ['user.read'],
      });
    } catch (e) {
      if (e.name === 'InteractionRequiredAuthError') {
        await msalInstance.acquireTokenPopup({
          account: accounts[0],
          scopes: ['user.read'],
        });
      } else {
        throw e;
      }
    }
  }
};

export {
  AuthProviderAzure,
  getIdTokenAzure,
  isAuthenticatedAzure,
  signOutAzure,
  forceRefreshTokensAzure,
  loginUserAzure,
};
