import { useMutation } from 'react-query';

import { axiosInstance } from '../../axios';

type ReturnType = {
  mutateAsync: any;
};

export type AddUsersPayloadType = {
  organizationId: string;
  email_ids: string[];
};

export const useAddUsers = (): ReturnType => {
  const { mutateAsync } = useMutation(
    ({ organizationId, ...requestBody }: AddUsersPayloadType) => {
      return axiosInstance.post(
        `/organizations/${organizationId}/users`,
        requestBody
      );
    }
  );

  return { mutateAsync };
};
