import { Box, Button, makeStyles } from '@material-ui/core';
import isEqual from 'lodash.isequal';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useGetPermissions } from '../../utils/useGetPermissions';
import { LoadingButton } from '../Atoms/LoadingButton';
import { useUpdateOrganization } from '../Organizations/useUpdateOrganization';
import {
  useFetchOrganization,
  useInvalidateFetchOrganization,
} from '../Users/useFetchOrganization';
import { useConfigurationContext } from './ConfigurationProvider';
import {
  useFetchOrganizationFeatures,
  useInvalidateOrganizationFeatures,
} from './hooks/useFetchOrganizationFeatures';
import { useSaveOrganizationFeatures } from './hooks/useSaveOrganizationFeatures';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    gap: '16px',
  },
}));

type RouteParam = { organizationId: string };

export const ConfirmationButtons = () => {
  const classes = useStyles();
  const { organizationId } = useParams<RouteParam>();
  const { data: organization } = useFetchOrganization(
    parseInt(organizationId, 10)
  );
  const { data: features } = useFetchOrganizationFeatures(
    parseInt(organizationId, 10)
  );
  const invalidateOrganization = useInvalidateFetchOrganization();
  const invalidateFeatures = useInvalidateOrganizationFeatures();
  const saveFeaturesMutation = useSaveOrganizationFeatures();
  const saveOrganizationMutation = useUpdateOrganization();
  const { state, dispatch } = useConfigurationContext();
  const {
    permissions: { canUpdateOrganization, canConfigureFeatures },
  } = useGetPermissions();

  const settingsHasChanged = React.useMemo(() => {
    if (organization && features) {
      return (
        !isEqual(state.info, organization) || !isEqual(state.features, features)
      );
    }

    return false;
  }, [organization, features, state]);

  const handleSave = async () => {
    if (!state.features || !state.info) return;

    try {
      if (canConfigureFeatures) {
        await saveFeaturesMutation.mutateAsync(
          {
            organizationId: parseInt(organizationId, 10),
            features: state.features,
          },
          {
            onSuccess: async () => {
              await invalidateFeatures(parseInt(organizationId, 10));
            },
          }
        );
      }

      if (canUpdateOrganization) {
        await saveOrganizationMutation.mutateAsync(
          {
            id: parseInt(organizationId, 10),
            allow_other_domain_users: state.info.allow_other_domain_users,
            auto_add_domain_users: state.info.auto_add_domain_users,
            allow_sharing_outside_org: state.info.allow_sharing_outside_org,
          },
          {
            onSuccess: async () => {
              await invalidateOrganization(parseInt(organizationId, 10));
            },
          }
        );
      }

      toast.success('Configuration saved successfully');
    } catch (error) {
      toast.error('Error in saving organization configuration');
    }
  };

  const handleCancel = () => {
    if (features && organization) {
      dispatch({
        type: 'SET_FEATURES',
        payload: { features: features },
      });

      dispatch({
        type: 'SET_ORGANIZATION_INFO',
        payload: { info: organization },
      });
    }
  };

  const disableButtons =
    !features ||
    !organization ||
    !canUpdateOrganization ||
    !canConfigureFeatures ||
    !settingsHasChanged;

  return (
    <Box className={classes.buttonsContainer}>
      <LoadingButton
        color='primary'
        variant='contained'
        disabled={disableButtons}
        onClick={handleSave}
        loading={
          saveFeaturesMutation.isLoading || saveOrganizationMutation.isLoading
        }
      >
        Save & Apply
      </LoadingButton>
      <Button
        color='primary'
        variant='outlined'
        disabled={
          disableButtons ||
          saveFeaturesMutation.isLoading ||
          saveOrganizationMutation.isLoading
        }
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </Box>
  );
};
