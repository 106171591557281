import { Box, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

import { Switch } from '../Atoms/Switch';
import { useConfigurationContext } from './ConfigurationProvider';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: '4px',
    paddingBottom: '4px',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 'none',
    },
    '&:first-child': {
      paddingTop: 'none',
    },
  },
  toggleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const FeatureToggle = ({
  name,
  disabled,
  id,
  additionalContent,
}: FeatureToggleType) => {
  const classes = useStyles();
  const { state, dispatch } = useConfigurationContext();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.toggleContainer}>
        <Typography variant='body1'>{name}</Typography>
        <Switch
          disabled={disabled}
          checked={state.features ? state.features[id] : false}
          onChange={() => {
            dispatch({ type: 'TOGGLE_FEATURE', payload: { featureId: id } });
          }}
        />
      </Box>
      {additionalContent ? additionalContent : null}
    </Box>
  );
};

type FeatureToggleType = {
  id: string;
  name: string;
  disabled?: boolean;
  additionalContent?: React.ReactNode;
};
