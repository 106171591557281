import * as React from 'react';

import type { OrganizationInfo } from '../Users/useFetchOrganization';
import type { ConfigurationReducerActions } from './configurationReducer';
import { configurationReducer } from './configurationReducer';
import type { FeatureSettings } from './hooks/useFetchOrganizationFeatures';

const ConfigurationContext =
  React.createContext<ConfigurationContextType | null>(null);

export const useConfigurationContext = (): ConfigurationContextType => {
  const value = React.useContext(ConfigurationContext);
  if (!value) {
    throw new Error(
      'useConfiguration must be used inside ConfigurationContextProvider'
    );
  }
  return value;
};

export const ConfigurationContextProvider = ({
  children,
  initialValue,
}: ConfigurationContextProviderType) => {
  const [state, dispatch] = React.useReducer(
    configurationReducer,
    initialValue
  );

  return (
    <ConfigurationContext.Provider value={{ state, dispatch }}>
      {children}
    </ConfigurationContext.Provider>
  );
};

type ConfigurationContextProviderType = {
  children: React.ReactNode;
  initialValue: SettingsType;
};

export type ConfigurationContextType = {
  state: SettingsType;
  dispatch: React.Dispatch<ConfigurationReducerActions>;
};

export type SettingsType = {
  features?: FeatureSettings;
  info?: OrganizationInfo;
};
