import { Button } from '@material-ui/core';
import * as React from 'react';

import { useFetchPricingLimits } from '../../hooks/useFetchPricingLimits';

export const AddUsersButton = ({
  handleClick,
}: AddUsersButtonProps): JSX.Element => {
  const { data, isLoading } = useFetchPricingLimits();

  return (
    <Button
      variant='contained'
      color='primary'
      type='button'
      onClick={handleClick}
      disabled={data?.is_seats_exceeded || isLoading}
    >
      Add Users
    </Button>
  );
};

type AddUsersButtonProps = {
  handleClick: () => void;
};
