import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../axios';

const QUERY_KEY = ['pricing_limits'];

export const useFetchPricingLimits = () => {
  return useQuery(QUERY_KEY, fetchLimits, {
    select: (data) => data.data.result,
  });
};

const fetchLimits = async () => axiosInstance.get<ResponseType>(`/limits`);

export const useInvalidateFetchPricingLimits = () => {
  const queryClient = useQueryClient();
  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
};

type ResponseType = {
  result: {
    is_seats_exceeded: boolean;
    seats_limit: number;
    seats_used: number;
    org_name: string;
  };
};
