import { Box, makeStyles, Modal, Typography } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '32px',
    width: 400,
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

type BasicModalProps = {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
};

const Title: React.FunctionComponent = ({ children }) => (
  <Typography id='modal-title' variant='h6' component='h2'>
    {children}
  </Typography>
);

const BasicModal = ({
  open,
  handleClose,
  children,
}: BasicModalProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalWrapper}>{children}</Box>
    </Modal>
  );
};

BasicModal.Title = Title;

export default BasicModal;
