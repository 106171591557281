import * as React from 'react';

import AddOrganizationModal from '../Organizations/AddOrganizationModal';
import AddUsersModal from '../Users/AddUsersModal';

type CommonModalType = {
  modalType: string;
  modalOpen: boolean;
  handleModalClose: () => void;
};

export const MODAL_TYPE = Object.freeze({
  ADD_ORGANIZATION: 'add_organization',
  ADD_USERS: 'add_users',
});

export const CommonModal = ({
  modalOpen,
  modalType,
  handleModalClose = () => {},
}: CommonModalType): JSX.Element => {
  switch (modalType) {
    case MODAL_TYPE.ADD_ORGANIZATION:
      return (
        <AddOrganizationModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />
      );
    case MODAL_TYPE.ADD_USERS:
      return (
        <AddUsersModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />
      );
    default:
      throw new Error('invalid modal type');
  }
};
