import { useMutation } from 'react-query';

import { axiosInstance } from '../../axios';

type ReturnType = {
  mutateAsync: any;
};

export type AddOrganizationPayloadType = {
  organization_name: string;
  domain?: string;
  description?: string;
  allow_other_domain_users?: boolean;
  auto_add_domain_users?: boolean;
};

export const useCreateOrganization = (): ReturnType => {
  const { mutateAsync } = useMutation(
    (requestBody: AddOrganizationPayloadType) => {
      return axiosInstance.post('/organizations', requestBody);
    }
  );

  return { mutateAsync };
};
