import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'grid',
    placeContent: 'center',
    placeItems: 'center',
    height: '100vh',
  },
}));

const Spinner = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
