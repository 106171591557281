import { Box, Button, Divider, makeStyles, Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { ReactComponent as NeedlLogo } from '../../assets/needl-logo.svg';
import { useLogout } from '../../hooks/useLogout';
import { NEEDL_ORGANIZATION_ID } from '../../utils/organizationId';
import { useGetPermissions } from '../../utils/useGetPermissions';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
  },
}));

const Navigation = (): JSX.Element => {
  const {
    permissions: { canViewAllOrganizations },
    organizationId,
  } = useGetPermissions();
  const classes = useStyles();
  const routeMatch = useRouteMatch([
    '/needlbox',
    '/organizations/:organizationId/users/:userId',
    '/organizations/:organizationId/users',
    '/organizations/:organizationId',
    '/organizations',
    '/',
  ]);
  const currentTab = routeMatch?.path;

  return (
    <Box className={classes.navigationContainer}>
      <Tabs value={currentTab} indicatorColor='primary' textColor='primary'>
        {/* <Tab label='Dashboard' value='/' to='/' component={Link} /> */}
        <Tab
          label='Users'
          value='/organizations/:organizationId/users'
          to={`/organizations/${organizationId}/users`}
          component={Link}
        />
        {canViewAllOrganizations && (
          <Tab
            label='Organizations'
            value='/organizations'
            to='/organizations'
            component={Link}
          />
        )}
        <Tab
          label='Organization Configuration'
          value='/organizations/:organizationId'
          to={`/organizations/${organizationId}`}
          component={Link}
        />
        {organizationId === NEEDL_ORGANIZATION_ID && (
          <Tab
            label='NeedlBox'
            value='/needlbox'
            to='/needlbox'
            component={Link}
          />
        )}
      </Tabs>
    </Box>
  );
};

export const Header = (): JSX.Element => {
  const classes = useStyles();
  const loginPageMatch = useRouteMatch('/login');
  const isLoginPage = !!loginPageMatch;
  const logoutUser = useLogout();

  const logoutHandler = async () => {
    await logoutUser();
  };

  return (
    <>
      <Box className={classes.headerContainer}>
        <Link to='/'>
          <NeedlLogo width='120' height='36' />
        </Link>
        {!isLoginPage && (
          <Box className={classes.navigationContainer}>
            <Navigation />
            <Button variant='outlined' color='primary' onClick={logoutHandler}>
              Logout
            </Button>
          </Box>
        )}
      </Box>
      <Divider variant='fullWidth' />
    </>
  );
};
