import { useHistory } from 'react-router-dom';

import { signOut } from '../Components/AuthProvider';
import { useRemovePermissions } from '../utils/useGetPermissions';

export const useLogout = (): ((error?: string) => Promise<void>) => {
  const history = useHistory();
  const removePermissions = useRemovePermissions();

  return async (error?: string) => {
    await signOut();
    removePermissions();
    history.replace('/login', { error });
  };
};
