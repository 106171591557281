import { Action } from '../../utils/types';

export const getAlertTitle = (action: Action): string => {
  switch (action.type) {
    case 'removeUsers':
      return 'Remove Organization users';
    case 'plan':
      return 'Change user plan';
    case 'role':
      return 'Change user role';
    default:
      return 'Alert';
  }
};

export const getAlertDescription = (action: Action): string => {
  const count = action.payload?.value.length;
  switch (action.type) {
    case 'removeUsers':
      return `Look like you're trying to remove ${count} ${`${
        count > 1 ? 'users' : 'user'
      }`} from organization. This action cannot be undone.`;
    default:
      return 'Are you sure ?';
  }
};

export const getAlertActionText = (action: Action): string => {
  switch (action.type) {
    case 'removeUsers':
      return `Yes, Remove`;
    default:
      return 'Yes, Change';
  }
};
