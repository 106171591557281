import { Box, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

import { useGetPermissions } from '../../utils/useGetPermissions';
import { ConfigurationContextProvider } from './ConfigurationProvider';
import { ConfirmationButtons } from './ConfirmationButtons';
import { OrganizationFeatures } from './OrganizationFeatures';
import { OrganizationInfo } from './OrganizationInfo';
import { SelectOrganization } from './SelectOrganization';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    margin: '0px auto',
    maxWidth: '1300px',
    padding: '0 16px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '64px',
    paddingBottom: '100px',
    paddingTop: '40px',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  headerContainer: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
  },
  buttonsContainer: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: '20px 0',
  },
}));

export const OrganizationConfiguration = () => {
  const classes = useStyles();
  const {
    permissions: { canViewAllOrganizations },
  } = useGetPermissions();
  return (
    <ConfigurationContextProvider initialValue={{}}>
      <Box className={`${classes.pageWrapper} ${classes.mainContainer}`}>
        <Box className={classes.infoContainer}>
          <Box className={classes.headerContainer}>
            <Typography variant='h4'>Organization Configuration</Typography>
            {canViewAllOrganizations ? <SelectOrganization /> : null}
          </Box>
          <OrganizationInfo />
        </Box>
        <OrganizationFeatures />
      </Box>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.pageWrapper}>
          <ConfirmationButtons />
        </Box>
      </Box>
    </ConfigurationContextProvider>
  );
};
