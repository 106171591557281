import { CircularProgress, makeStyles } from '@material-ui/core';
import type * as React from 'react';

type FullPageLazyLoadingFallbackType = { style?: React.CSSProperties };

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'grid',
    placeContent: 'center',
    background: theme.palette.background.default,
  },
}));

export const FullPageLazyLoadingFallback = ({
  style = {},
}: FullPageLazyLoadingFallbackType) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <CircularProgress />
    </div>
  );
};
