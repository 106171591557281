import { useMutation } from 'react-query';

import { axiosInstance } from '../../../axios';
import type { FeatureSettings } from './useFetchOrganizationFeatures';

export const useSaveOrganizationFeatures = () => {
  return useMutation((requestPayload: UpdateFeaturesRequestType) =>
    axiosInstance.put(
      `/organizations/${requestPayload.organizationId}/features/`,
      {
        settings: requestPayload.features,
      }
    )
  );
};

type UpdateFeaturesRequestType = {
  organizationId: number;
  features: FeatureSettings;
};
