import * as React from 'react';

import { TableDataType } from '../utils/types';

type PropsType = {
  data: TableDataType;
  searchColumns?: string[];
  setTableRows: (filteredRows: TableDataType) => void;
};

type ReturnType = {
  searchValue: string;
  handleSearch: (searchValue: string) => void;
};

const getSearchRegex = (value: string): RegExp => {
  return new RegExp(value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
};

export const useTableSearch = ({
  data,
  searchColumns,
  setTableRows,
}: PropsType): ReturnType => {
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);

    const searchRegex = getSearchRegex(searchValue);
    const filteredRows = data.filter((row: any) => {
      const columnsToSearch = searchColumns || Object.keys(row);
      return columnsToSearch.some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });

    setTableRows(filteredRows);
  };

  return { searchValue, handleSearch };
};
