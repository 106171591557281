import { useMutation } from 'react-query';

import { axiosInstance } from '../../axios';

type RequestPayloadType = {
  organizationId: string;
  user_ids: string[];
};

export const useRemoveUsersFromOrganization = (): any => {
  const { mutateAsync } = useMutation(
    ({ organizationId, user_ids }: RequestPayloadType) => {
      return axiosInstance.delete(`/organizations/${organizationId}/users`, {
        data: { user_ids },
      });
    }
  );

  return mutateAsync;
};
