import { Box, makeStyles, Select } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFetchOrganizations } from '../Users/useFetchOrganizations';

type RouteParam = { organizationId: string };

const useStyles = makeStyles(() => ({
  select: {
    padding: '8px 8px',
    width: '200px',
    marginTop: '4px',
  },
}));

export const SelectOrganization = () => {
  const classes = useStyles();
  const { isLoading, organizations } = useFetchOrganizations();
  const { organizationId } = useParams<RouteParam>();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedOrgId = event.target.value as number;
    history.push(`/organizations/${selectedOrgId}`);
  };

  if (isLoading || !organizations) {
    return null;
  }

  return (
    <Box>
      <Select
        variant='outlined'
        value={parseInt(organizationId, 10)}
        onChange={handleChange}
        native
        classes={{ select: classes.select }}
      >
        {organizations.map((org) => (
          <option key={org.id} value={org.id}>
            {org.organization_name}
          </option>
        ))}
      </Select>
    </Box>
  );
};
