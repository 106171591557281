import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

import { SourceCell, UploadCell } from './TableCells';

export const getColumns = (): GridColDef[] => [
  {
    headerName: 'ID',
    field: 'id',
    width: 300,
    hide: true,
  },
  {
    headerName: 'Source Name',
    field: 'label',
    width: 550,
    renderCell: SourceCell,
  },
  {
    headerName: '',
    field: 'thumbnail_location',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    width: 150,
    renderCell: UploadCell,
  },
];
