import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

import { GBToSize } from '../../utils/GBToSize';
import { OptionsType, PermissionsType } from '../../utils/types';
import DateViewerCell from '../common/DateViewerCell';
import DropdownCell from '../common/DropDownCell';
import NavigationCell from '../common/NavigationCell';

type ColumnsType = {
  options: OptionsType;
} & Pick<PermissionsType, 'canChangeUserPlan' | 'canChangeUserRole'>;

export const getColumns = ({
  options,
  canChangeUserPlan,
  canChangeUserRole,
}: ColumnsType): GridColDef[] => [
  {
    headerName: 'ID',
    field: 'id',
    hide: true,
  },
  {
    headerName: 'Username',
    field: 'username',
    width: 200,
  },
  {
    headerName: 'Email',
    field: 'email',
    width: 300,
    renderCell: ({ value }) => <NavigationCell value={value} />,
  },
  {
    headerName: 'Profession',
    field: 'profession',
    width: 220,
    hide: true,
  },
  {
    headerName: 'Industry',
    field: 'industry',
    width: 220,
    hide: true,
  },
  {
    headerName: 'Country',
    field: 'country',
    width: 200,
  },
  {
    headerName: 'Role',
    field: 'role',
    width: 150,
    editable: canChangeUserRole,
    valueOptions: options?.role || [],
    type: 'singleSelect',
    ...(canChangeUserRole && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'Total Storage',
    field: 'total_storage',
    width: 200,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => GBToSize(Number(value) || 0),
  },
  {
    headerName: 'Apps',
    field: 'no_of_private_sources',
    width: 150,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => value || 0,
  },
  {
    headerName: 'Websites',
    field: 'no_of_public_sources',
    width: 170,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => value || 0,
  },
  {
    headerName: 'Signed Up',
    field: 'signed_up',
    width: 250,
    type: 'date',
    renderCell: ({ value }) => <DateViewerCell value={value} />,
  },
];
