import { useMutation } from 'react-query';

import { axiosInstance } from '../../axios';
import { UserAttributeType } from '../../utils/types';

type RequestPayloadType = {
  organizationId: string;
  public_uuid: string;
  value: string;
};

export const useChangeUserAttribute = (attribute: UserAttributeType): any => {
  const { mutateAsync } = useMutation(
    ({ organizationId, public_uuid, value }: RequestPayloadType) => {
      return axiosInstance.put(
        `/organizations/${organizationId}/users/${public_uuid}/${attribute}`,
        { [attribute]: value }
      );
    }
  );

  return mutateAsync;
};
