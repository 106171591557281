import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../../axios';
import { OrganizationType } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';

type ResponseType = {
  results: OrganizationType[];
};

type ReturnType = {
  isLoading: boolean;
  organizations: OrganizationType[];
};

const QUERY_KEY = 'organizations';
const fetchOrganizations = () =>
  axiosInstance.get<ResponseType>('/organizations');

export const useFetchOrganizations = (): ReturnType => {
  const {
    permissions: { canViewAllOrganizations },
  } = useGetPermissions();

  const { data, isLoading } = useQuery([QUERY_KEY], fetchOrganizations, {
    enabled: canViewAllOrganizations,
  });

  const organizations = data?.data.results || [];
  return { isLoading, organizations };
};

export const useInvalidateFetchOrganizations = (): (() => void) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([QUERY_KEY]);
  };
};
