import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Field, Form, Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { useGetPermissions } from '../../utils/useGetPermissions';
import { isAuthenticated, signOut } from '../AuthProvider';
import { loginUserAzure } from '../AuthProvider/azure';
import { Header } from '../Header';
import { OAuthForm } from './OAuthForm';

const useStyles = makeStyles(() => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100vw',
    minHeight: '90vh',
  },
  loginCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
  formFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'red',
  },
  loginButtonContainer: {
    marginTop: '16px',
    width: '100%',
  },
}));

type LoginFormValues = {
  email?: string;
  password?: string;
};

const validate = (values: LoginFormValues) => {
  const errors: FormikErrors<LoginFormValues> = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .strict(true)
    .trim('Email contains white spaces, please remove them')
    .email('Please provide a valid email')
    .required('Please provide a valid email'),
  password: yup
    .string()
    .strict(true)
    .min(8, 'Password must be atleast 8 characters long')
    .required('Please provide a valid password'),
});

const LoginImpl = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { refetch } = useGetPermissions({ enabled: false });
  const { state } = useLocation<{ error: string }>();
  const [error, setError] = React.useState(state?.error);

  const handleLogin = React.useCallback(async () => {
    const isUserAuthenticated = await isAuthenticated();
    if (isUserAuthenticated) {
      const { data } = await refetch();
      const organizationId = data?.data?.result?.organizationId;
      if (organizationId) {
        history.replace(`/organizations/${organizationId}/users`);
      } else {
        setError('Unable to fetch user organization');
        setTimeout(() => signOut(), 3000);
      }
    } else {
      setError('You do not have necessary permissions.');
      setTimeout(() => signOut(), 3000);
    }
  }, [history, refetch]);

  React.useEffect(() => {
    (async () => {
      try {
        // handle the sso login
        await handleLogin();
      } catch (error) {
        // ignore if no user logged in
      }
    })();
  }, [handleLogin]);

  if (process.env.REACT_APP_CLOUD_PROVIDER === 'azure') {
    // TODO: this is a sample azure login redirect page. confirm with product and replace with actual design
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <button
          onClick={async () => {
            await loginUserAzure();
          }}
          style={{
            padding: '20px 40px',
            borderRadius: '20px',
            fontSize: '20px',
            fontWeight: 'bold',
            cursor: 'pointer',
            background: '#367d8d',
            color: 'white',
          }}
        >
          Login with your Microsoft account into Admin tool
        </button>
      </div>
    );
  }

  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.loginCardContainer}>
        <Typography variant='h5' align='center' component='h2' gutterBottom>
          Login to Admin tool
        </Typography>
        <OAuthForm />
        <Typography style={{ margin: '25px' }} align='center'>
          ---or---
        </Typography>
        <Formik
          validateOnChange={true}
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async (values: LoginFormValues, { setSubmitting }: any) => {
            setSubmitting(true);
            setError(null);
            try {
              await Auth.signIn({
                username: values.email,
                password: values.password.trim(),
              });
              await handleLogin();
            } catch (e) {
              if (e && e.code === 'UserNotConfirmedException') {
                setError('Unverified user.');
              } else if (e && e.code === 'NotAuthorizedException') {
                setError('Incorrect username or password.');
              } else setError('Unknown error.');
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
          validate={validate}
        >
          {({ isSubmitting, isValid }: any) => (
            <Form>
              <Box className={classes.formFieldsContainer}>
                <Box marginTop='24px'>
                  <Field
                    name='email'
                    fullWidth
                    required
                    as={TextField}
                    type='email'
                    label='Email'
                  />
                </Box>
                <Box marginTop='24px' marginBottom='24px'>
                  <Field
                    name='password'
                    fullWidth
                    required
                    as={TextField}
                    type='password'
                    label='Password'
                  />
                </Box>
              </Box>
              {error && (
                <Box width='90%'>
                  <Typography>
                    <Box className={classes.errorText} component='span'>
                      {error}
                    </Box>
                  </Typography>
                </Box>
              )}
              <Box className={classes.loginButtonContainer}>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  fullWidth
                  disabled={!isValid || isSubmitting}
                >
                  Login
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

const Login = (): JSX.Element => {
  return (
    <>
      <Header />
      <LoginImpl />
    </>
  );
};

export default Login;
