import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../../../axios';

const QUERY_KEY = 'ORGANIZATION_FEATURE_SETTINGS';

export const useFetchOrganizationFeatures = (org_id: number) => {
  return useQuery(
    [QUERY_KEY, org_id],
    async () =>
      await axiosInstance.get<ResponseType>(
        `/organizations/${org_id}/features`
      ),
    {
      select: (data) => data.data.settings,
    }
  );
};

export const useInvalidateOrganizationFeatures = () => {
  const queryClient = useQueryClient();

  return (org_id: number) => {
    return queryClient.invalidateQueries([QUERY_KEY, org_id]);
  };
};

type ResponseType = {
  settings: FeatureSettings;
};

export type FeatureSettings = {
  [key: string]: boolean;
};
