import * as React from 'react';

import { ReactChildrenType } from '../../utils/types';
import {
  AuthProviderAws,
  forceRefreshTokensAws,
  getIdTokenAws,
  isAuthenticatedAws,
  signOutAws,
} from './aws';
import {
  AuthProviderAzure,
  forceRefreshTokensAzure,
  getIdTokenAzure,
  isAuthenticatedAzure,
  signOutAzure,
} from './azure';
import { loadRedirections } from './util';

const cloudProvider = process.env.REACT_APP_CLOUD_PROVIDER;
const isAuthDisabled =
  process.env.REACT_APP_DISABLE_AUTH === 'true' || process.env.VITEST;

const AuthProvider: React.FunctionComponent<ReactChildrenType> = ({
  children,
}) => {
  if (isAuthDisabled) {
    return <>{children}</>;
  }

  if (cloudProvider === 'aws') {
    return <AuthProviderAws>{children}</AuthProviderAws>;
  } else if (cloudProvider === 'azure') {
    return <AuthProviderAzure>{children}</AuthProviderAzure>;
  }
  throw new Error('Cloud provider not supported');
};

const getIdToken = async () => {
  if (isAuthDisabled) {
    return 'test_token';
  }

  if (cloudProvider === 'aws') {
    return await getIdTokenAws();
  } else if (cloudProvider === 'azure') {
    return await getIdTokenAzure();
  }
  throw new Error('Cloud provider not supported');
};

const isAuthenticated = async () => {
  if (isAuthDisabled) {
    return true;
  }

  let isAuthenticatedHandler: () => Promise<boolean>;
  if (cloudProvider === 'aws') {
    isAuthenticatedHandler = isAuthenticatedAws;
  } else if (cloudProvider === 'azure') {
    isAuthenticatedHandler = isAuthenticatedAzure;
  } else {
    throw new Error('Cloud provider not supported');
  }

  const isAuthenticated = await isAuthenticatedHandler();
  loadRedirections(isAuthenticated);
  return isAuthenticated;
};

const signOut = async () => {
  if (isAuthDisabled) {
    return;
  }

  if (cloudProvider === 'aws') {
    return await signOutAws();
  } else if (cloudProvider === 'azure') {
    return await signOutAzure();
  }
  throw new Error('Cloud provider not supported');
};

const forceRefreshTokens = async () => {
  if (isAuthDisabled) {
    return;
  }

  if (cloudProvider === 'aws') {
    return await forceRefreshTokensAws();
  } else if (cloudProvider === 'azure') {
    return await forceRefreshTokensAzure();
  }
  throw new Error('Cloud provider not supported');
};

export {
  AuthProvider,
  getIdToken,
  isAuthenticated,
  signOut,
  forceRefreshTokens,
};
