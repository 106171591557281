import { ReactComponent as Collaboration } from '../../assets/collaboration.svg';
import { ReactComponent as ConnectedSources } from '../../assets/connected_sources.svg';
import { ReactComponent as CoreFeatures } from '../../assets/core_features.svg';
import { ReactComponent as DataManagement } from '../../assets/data_management.svg';
import { ReactComponent as Others } from '../../assets/others.svg';

const FEATURE_GROUPS = {
  CORE_FEATURES: 'core_features',
  DATA_MANAGEMENT: 'data_management',
  COLLABORATION: 'collaboration',
  CONNECTED_SOURCES: 'connected_sources',
  OTHERS: 'others',
};

export const getFeatureGroupIcons = (featureGroupId: string) => {
  switch (featureGroupId) {
    case FEATURE_GROUPS.CORE_FEATURES:
      return CoreFeatures;
    case FEATURE_GROUPS.DATA_MANAGEMENT:
      return DataManagement;
    case FEATURE_GROUPS.COLLABORATION:
      return Collaboration;
    case FEATURE_GROUPS.CONNECTED_SOURCES:
      return ConnectedSources;
    default:
      return Others;
  }
};
