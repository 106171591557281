import * as React from 'react';
import { Switch, useHistory } from 'react-router-dom';

import { axiosInstance } from '../axios';
import { getIdToken } from '../Components/AuthProvider';
import Login from '../Components/Login';
import App from './App';
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';

const Pages = (): JSX.Element => {
  const history = useHistory();

  React.useEffect(() => {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const jwt = await getIdToken();
        return {
          ...config,
          headers: {
            ...config.headers,
            token: jwt,
          },
        };
      },
      (error) => Promise.reject(error)
    );
  }, [history]);

  return (
    <Switch>
      <AuthRoute path='/login'>
        <Login />
      </AuthRoute>
      <ProtectedRoute path='/'>
        <App />
      </ProtectedRoute>
    </Switch>
  );
};

export default Pages;
