import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';

import BasicModal from '../common/BasicModal';
import { useInvalidateFetchOrganizations } from '../Users/useFetchOrganizations';
import { useCreateOrganization } from './useCreateOrganization';

const useStyles = makeStyles(() => ({
  formFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'red',
  },
  addButtonContainer: {
    marginTop: '16px',
    width: '100%',
  },
}));

type AddOrganizationModalProps = {
  modalOpen: boolean;
  handleModalClose: () => void;
};

const AddOrganization = ({
  handleModalClose,
}: Pick<AddOrganizationModalProps, 'handleModalClose'>): JSX.Element => {
  const classes = useStyles();
  const [error, setError] = React.useState<string>();

  const { mutateAsync: addOrganization } = useCreateOrganization();
  const invalidateFetchOrganizations = useInvalidateFetchOrganizations();

  return (
    <Formik
      initialValues={{ organization_name: '', domain: '' }}
      onSubmit={async ({ organization_name, domain }, { setSubmitting }) => {
        setError('');
        setSubmitting(true);
        try {
          await addOrganization({
            organization_name,
            domain,
          });

          invalidateFetchOrganizations();
          setSubmitting(false);
          handleModalClose();
        } catch ({ response }) {
          const errorMesage =
            response?.data?.error ||
            'Something went wrong. Please try again later';
          setSubmitting(false);
          setError(errorMesage);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Box className={classes.formFieldsContainer}>
            <Box marginTop='24px'>
              <Field
                name='organization_name'
                fullWidth
                required
                as={TextField}
                type='text'
                label='Name'
              />
            </Box>
            <Box marginTop='24px' marginBottom='24px'>
              <Field
                name='domain'
                fullWidth
                as={TextField}
                type='text'
                label='Domain'
              />
            </Box>
          </Box>
          {error && (
            <Box width='90%'>
              <Typography>
                <Box className={classes.errorText} component='span'>
                  {error}
                </Box>
              </Typography>
            </Box>
          )}
          <Box className={classes.addButtonContainer}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={!isValid || isSubmitting}
            >
              Add
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const AddOrganizationModal = ({
  modalOpen,
  handleModalClose,
}: AddOrganizationModalProps): JSX.Element => {
  return (
    <BasicModal open={modalOpen} handleClose={handleModalClose}>
      <BasicModal.Title>Add Organization</BasicModal.Title>
      <AddOrganization handleModalClose={handleModalClose} />
    </BasicModal>
  );
};

export default AddOrganizationModal;
