import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useTableExport } from '../../hooks/useTableExport';
import { useTableSearch } from '../../hooks/useTableSearch';
import { OrganizationType } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';
import { ActionsMenu } from '../common/ActionsMenu';
import { CommonModal, MODAL_TYPE } from '../common/CommonModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import SearchInput from '../common/SearchInput';
import {
  useFetchOrganizations,
  useInvalidateFetchOrganizations,
} from '../Users/useFetchOrganizations';
import { getColumns } from './columns';
import {
  updateOrganizationPayloadType,
  useUpdateOrganization,
} from './useUpdateOrganization';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    width: '100%',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));

type ActionType = {
  payload: updateOrganizationPayloadType;
};

const Organizations = (): JSX.Element => {
  const {
    permissions: {
      canCreateOrganization,
      canViewAllOrganizations,
      canUpdateOrganization,
    },
  } = useGetPermissions();

  const { isLoading, organizations } = useFetchOrganizations();
  const invalidateFetchOrganizations = useInvalidateFetchOrganizations();

  const columns = React.useMemo(
    () => getColumns({ canUpdateOrganization }),
    [canUpdateOrganization]
  );

  const [rows, setRows] = React.useState(organizations);
  const [selectedOrganizationIds, setSelectedOrganizationIds] = React.useState<
    number[]
  >([]);
  const [showDialog, setShowDialog] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [isButtonLoading, setButtonLoading] = React.useState(false);
  const [action, setAction] = React.useState<ActionType>();

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const { mutateAsync: updateOrganizationInfo } = useUpdateOrganization();

  const { searchValue, handleSearch } = useTableSearch({
    data: organizations,
    setTableRows: (filteredRows) => setRows(filteredRows as OrganizationType[]),
  });

  const dataToExport = React.useMemo(
    () =>
      selectedOrganizationIds.length
        ? rows.filter(({ id }) => selectedOrganizationIds.includes(id))
        : rows,
    [rows, selectedOrganizationIds]
  );

  const downloadExcel = useTableExport({ data: dataToExport, type: 'xlsx' });
  const downloadCSV = useTableExport({ data: dataToExport, type: 'csv' });

  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    setRows(organizations);
  }, [organizations]);

  const cellClickHandler = ({ field, id }: any) => {
    if (['total_users', 'total_admin_users'].includes(field)) {
      history.push(`/organizations/${id}/users`);
    }

    if (field === 'organization_name') {
      history.push(`/organizations/${id}`);
    }
  };

  const cellValueChangeHandler = ({ field, value, id }: any) => {
    setRows([...rows]);
    if (
      [
        'allow_other_domain_users',
        'auto_add_domain_users',
        'allow_sharing_outside_org',
      ].includes(field)
    ) {
      setShowDialog(true);
      setAction({
        payload: { id, [field]: value === 'true' },
      });
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
    setError('');
  };

  const actionHandler = async () => {
    try {
      setButtonLoading(true);
      const { status } = await updateOrganizationInfo(action.payload);
      if (status === 200) {
        invalidateFetchOrganizations();
        handleSearch('');
        closeDialog();
      } else {
        setError('Something went wrong! Please try later');
      }
    } catch (e) {
      setError('Something went wrong! Please try later');
    } finally {
      setButtonLoading(false);
    }
  };

  if (!canViewAllOrganizations) return null;

  return (
    <>
      <Box className={classes.rootContainer}>
        <Box className={classes.buttonContainer}>
          <Typography variant='h4'>List of Organizations</Typography>
          <Box display='flex' alignItems='flex-end'>
            <SearchInput value={searchValue} handleSearch={handleSearch} />
            {canCreateOrganization && (
              <Button
                variant='contained'
                color='primary'
                type='button'
                onClick={handleModalOpen}
              >
                Add Organization
              </Button>
            )}
            <ActionsMenu
              items={[
                {
                  label: 'Export as xlsx',
                  action: downloadExcel,
                },
                {
                  label: 'Export as csv',
                  action: downloadCSV,
                },
              ]}
            />
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              autoPageSize
              disableSelectionOnClick
              isCellEditable={() => true}
              onCellEditCommit={cellValueChangeHandler}
              onCellClick={cellClickHandler}
              checkboxSelection
              onSelectionModelChange={(selectedUserIds) => {
                setSelectedOrganizationIds(selectedUserIds as number[]);
              }}
            />
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        show={showDialog}
        closeDialog={closeDialog}
        actionHandler={actionHandler}
        errorMessage={error}
        isLoading={isButtonLoading}
      />
      <CommonModal
        modalType={MODAL_TYPE.ADD_ORGANIZATION}
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default Organizations;
