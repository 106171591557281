import { Box, makeStyles, Typography } from '@material-ui/core';
import { GridCellValue } from '@mui/x-data-grid';
import * as React from 'react';

import { TableCellProps } from '../../utils/types';

const useStyles = makeStyles(() => ({
  cellContainer: { textDecoration: 'underline', cursor: 'pointer' },
}));

const DateViewerCell = ({ value }: TableCellProps): JSX.Element => {
  const classes = useStyles();

  const date = new Date(value as string);
  const displayValue = date.toDateString();

  return (
    <Box className={classes.cellContainer}>
      <Typography>{displayValue}</Typography>
    </Box>
  );
};

export default DateViewerCell;
