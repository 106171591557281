export const GBToSize = (GB: number, decimals = 2): string => {
  if (GB === 0) return '0 GB';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(GB) / Math.log(k));

  return `${parseFloat((GB / Math.pow(k, i)).toFixed(dm))} ${sizes[i + 3]}`;
};
