import type { ButtonProps } from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  LoadingButtonProps
>(({ loading = false, children, disabled, classes, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      disabled={disabled || loading}
      classes={classes}
      {...props}
      {...(loading && {
        startIcon: <CircularProgress size={16} style={{ color: 'gray' }} />,
      })}
    >
      {children}
    </Button>
  );
});
