import { Box, makeStyles, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as React from 'react';

import { TableCellProps } from '../../utils/types';

const useStyles = makeStyles(() => ({
  cellContainer: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const DropdownCell = ({ value }: TableCellProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Tooltip placement='bottom-start' title='Double click to edit'>
      <Box className={classes.cellContainer}>
        {value}
        <KeyboardArrowDownIcon />
      </Box>
    </Tooltip>
  );
};

export default DropdownCell;
