import { Box, Button, Typography } from '@material-ui/core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFetchPricingLimits } from '../../hooks/useFetchPricingLimits';
import { useFetchOrganization } from './useFetchOrganization';

type RouteParam = { organizationId: string };

export const UserListSeatManagement: React.FC = () => {
  const { organizationId } = useParams<RouteParam>();
  const history = useHistory();

  const { data: priceLimits, isLoading: isPriceLimitsLoading } =
    useFetchPricingLimits();

  const { isLoading, data: organization } = useFetchOrganization(
    parseInt(organizationId, 10)
  );

  const navigateToUserSettings = () => {
    history.push(`${process.env.REACT_APP_MAIN_URL}/settings/user-settings`);
  };

  if (
    isLoading ||
    isPriceLimitsLoading ||
    Number(organizationId) !== Number(organization.id)
  ) {
    return null;
  }

  return (
    <Typography variant='subtitle2'>
      <Box>
        {isPriceLimitsLoading || priceLimits?.seats_limit === 0
          ? null
          : `${priceLimits?.seats_used ?? 0} out of ${
              priceLimits?.seats_limit ?? 0
            } seats used. `}
        <Button
          variant='contained'
          size='small'
          color='primary'
          component='a'
          href={`${process.env.REACT_APP_MAIN_URL}/settings/user-settings`}
        >
          Manage seats
        </Button>
      </Box>
    </Typography>
  );
};
