import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isAuthenticated } from '../Components/AuthProvider';

type AuthRoutePropsType = {
  children: React.ReactNode;
  path: string;
};

const AuthRoute = (props: AuthRoutePropsType): JSX.Element => {
  const [isUserAuthenticated, setIsUserAuthenticated] =
    React.useState<boolean>();

  React.useEffect(() => {
    (async () => {
      const result = await isAuthenticated();
      setIsUserAuthenticated(result);
    })();
  }, []);

  if (isUserAuthenticated === undefined) return null;
  if (isUserAuthenticated) return <Redirect to='/' />;
  return <Route {...props} />;
};

export default AuthRoute;
