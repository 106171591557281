import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';

import { GBToSize } from '../../utils/GBToSize';
import DateViewerCell from '../common/DateViewerCell';

export const PRIVATE_SOURCES_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    hide: true,
  },
  {
    headerName: 'App',
    field: 'source',
    width: 250,
  },
  {
    headerName: 'Account Info',
    field: 'verifiable_id',
    width: 250,
  },
  {
    headerName: 'Connection Status',
    field: 'connected',
    width: 200,
    type: 'boolean',
    headerAlign: 'center',
    renderCell: ({ value }) => (value ? 'Linked' : 'Delinked'),
  },
  {
    headerName: 'Sync Status',
    field: 'token_valid',
    width: 200,
    type: 'boolean',
    headerAlign: 'center',
    renderCell: ({ value }) => (value ? 'Syncing' : 'Sync Stopped'),
  },
  {
    headerName: 'Crawl Preferences',
    field: 'crawler_preferences_selected',
    width: 200,
    type: 'boolean',
    headerAlign: 'center',
    renderCell: ({ value }) =>
      value === '-' ? '-' : value ? 'Selected' : 'Not Selected',
  },
  {
    headerName: 'Storage',
    field: 'storage',
    width: 200,
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => GBToSize(Number(value) || 0),
  },
  {
    headerName: 'Linked on',
    field: 'first_linked',
    width: 250,
    type: 'date',
    renderCell: ({ value }) => <DateViewerCell value={value} />,
  },
];

export const PUBLIC_SOURCES_COLUMNS: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    hide: true,
  },
  {
    headerName: 'Source',
    field: 'label',
    width: 300,
  },
  {
    headerName: 'Type',
    field: 'type',
    width: 150,
  },
];
