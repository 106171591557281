import {
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Upload } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import * as React from 'react';
import toast from 'react-hot-toast';

import { axiosInstance } from '../../axios';
import { bytesToMB } from '../../utils/bytesToMB';
import { useUpdatePublicSource } from './useUpdatePublicSource';
import { useUploadFile } from './useUploadFile';

const ONE_MB = 1 * 1024 * 1024;

export const SourceCell = ({
  value,
  row,
}: GridRenderCellParams): JSX.Element => {
  return (
    <>
      {row.thumbnail_location && (
        <img
          style={{
            maxWidth: '32px',
            maxHeight: '32px',
            marginRight: '8px',
          }}
          src={row.thumbnail_location}
          alt={value}
        />
      )}
      <Tooltip placement='bottom-start' title={value}>
        <Typography className='truncate'>{value}</Typography>
      </Tooltip>
    </>
  );
};

const getThumbnailLocation = (uploadUrl: string) => {
  const url = new URL(uploadUrl);
  return `${url.origin}${url.pathname}`;
};

export const UploadCell = ({ row }: GridRenderCellParams): JSX.Element => {
  const [uploading, setUploading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const uploadMutation = useUploadFile();
  const updateMutation = useUpdatePublicSource();

  const handleUpload = async (file: File) => {
    if (file.size > ONE_MB) {
      toast.error(
        `File is too large, file size is ${bytesToMB(
          file.size
        )} MB, maximum allowed size - 1 MB.`
      );
      return;
    }

    try {
      setUploading(true);
      const sourceId = row.id;
      const { data } = await axiosInstance.get<{ upload_url: string }>(
        `/needlbox/public_sources/${sourceId}/upload_url`
      );
      const uploadUrl = data.upload_url;

      await uploadMutation.mutateAsync({ uploadUrl, file });
      await updateMutation.mutateAsync({
        sourceId,
        thumbnailLocation: getThumbnailLocation(uploadUrl),
      });

      toast.success('Thumbnail uploaded successfully');
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <Button
        variant='outlined'
        color='primary'
        disabled={uploading}
        startIcon={<Upload />}
        endIcon={uploading ? <CircularProgress size={16} /> : null}
        onClick={() => inputRef.current?.click()}
      >
        Upload
      </Button>
      <input
        ref={inputRef}
        type='file'
        accept='image/*'
        id={`thumbnail-${row.id}`}
        name='thumbnail'
        hidden
        onChange={(e) => handleUpload(e.target.files[0])}
      />
    </div>
  );
};
