import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Spinner from '../Components/common/Spinner';
import Dashboard from '../Components/Dashboard';
import { Header } from '../Components/Header';
import { NeedlBox } from '../Components/NeedlBox';
import { OrganizationConfiguration } from '../Components/OrganizationConfiguration';
import OrganizationInfo from '../Components/OrganizationInfo';
import Organizations from '../Components/Organizations';
import UserActivity from '../Components/UserActivity';
import Users from '../Components/Users';
import { NEEDL_ORGANIZATION_ID } from '../utils/organizationId';
import { useGetPermissions } from '../utils/useGetPermissions';

const App = (): JSX.Element => {
  const { isLoading, isError, organizationId } = useGetPermissions();
  if (isLoading) return <Spinner />;
  if (isError) return null;

  return (
    <>
      <Header />
      <Switch>
        {organizationId === NEEDL_ORGANIZATION_ID && (
          <Route path='/needlbox'>
            <NeedlBox />
          </Route>
        )}
        <Route path='/organizations/:organizationId/users/:userId'>
          <UserActivity />
        </Route>
        <Route path='/organizations/:organizationId/users'>
          <Users />
        </Route>
        <Route path='/organizations/:organizationId'>
          <OrganizationConfiguration />
        </Route>
        <Route path='/organizations/'>
          <Organizations />
        </Route>
        <Route path='/'>
          {/* <Dashboard /> */}
          <Redirect to={`/organizations/${organizationId}/users`} />
        </Route>
      </Switch>
    </>
  );
};

export default App;
