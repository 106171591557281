const DEFAULT_ORGANIZATION_ID = 1;
const NEEDL_ORGANIZATION_ID = 2;

const COMMON_ORGANIZATION_IDS = [
  DEFAULT_ORGANIZATION_ID,
  NEEDL_ORGANIZATION_ID,
];

export {
  DEFAULT_ORGANIZATION_ID,
  NEEDL_ORGANIZATION_ID,
  COMMON_ORGANIZATION_IDS,
};
