import * as React from 'react';

import { TableDataType } from '../utils/types';

type PropsType = {
  data: TableDataType;
  type: 'xlsx' | 'csv';
};

const formatData = (data: TableDataType): Record<string, any>[] => {
  return data.map(({ id: _, ...rest }) => {
    return rest;
  });
};

export const useTableExport = ({ data, type }: PropsType): (() => void) => {
  const formattedData = React.useMemo(() => formatData(data), [data]);

  return async () => {
    const XLSX = await import('xlsx');
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DATA');
    XLSX.writeFile(workbook, `Data.${type}`);
  };
};
