import { IconButton, makeStyles, TextField } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    marginRight: '24px',
  },
}));

type SearchInputProps = {
  handleSearch: (search: string) => void;
  value: string;
};

const SearchInput = ({
  value,
  handleSearch,
}: SearchInputProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TextField
      variant='standard'
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(event.target.value)
      }
      placeholder='Search'
      className={classes.input}
      InputProps={{
        startAdornment: <SearchIcon />,
        endAdornment: (
          <IconButton
            title='Clear'
            size='small'
            style={{ visibility: value ? 'visible' : 'hidden' }}
            onClick={() => handleSearch('')}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchInput;
