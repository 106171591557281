import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  PrivateSourceType,
  PublicSourceType,
  UserBasicInfoType,
} from '../../utils/types';
import { PRIVATE_SOURCES_COLUMNS, PUBLIC_SOURCES_COLUMNS } from './columns';
import { useFetchUserActivity } from './useFetchUserActivity';

const useStyles = makeStyles(() => ({
  rootContainer: {
    padding: '40px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '50px',
  },
  tableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
    width: '99%',
    marginTop: '20px',
  },
  publicTableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '660px',
    width: '520px',
    marginTop: '20px',
  },
}));

type RouteParam = {
  organizationId: string;
  userId: string;
};

type UserDetailsPropsType = {
  details: UserBasicInfoType;
};

type PrivateIntegrationsTablePropsType = {
  private_sources: PrivateSourceType[];
};

type PublicIntegratiobsTablePropsType = {
  public_sources: PublicSourceType[];
};

const UserDetails = ({ details }: UserDetailsPropsType): JSX.Element => {
  const classes = useStyles();
  const {
    username,
    email,
    profession,
    industry,
    country,
    plan,
    feeds_created,
    feeds_shared,
  } = details;

  return (
    <Box className={classes.detailsContainer}>
      <Typography>
        {' '}
        <Typography variant='h6' component='span'>
          Username:
        </Typography>
        &nbsp;{username}
      </Typography>
      <Typography>
        {' '}
        <Typography variant='h6' component='span'>
          Email:
        </Typography>
        &nbsp;{email}
      </Typography>
      <Typography>
        {' '}
        <Typography variant='h6' component='span'>
          Profession:
        </Typography>
        &nbsp;{profession}
      </Typography>
      <Typography>
        {' '}
        <Typography variant='h6' component='span'>
          Industry:
        </Typography>
        &nbsp;{industry}
      </Typography>
      <Typography>
        {' '}
        <Typography variant='h6' component='span'>
          Plan:
        </Typography>
        &nbsp;{plan}
      </Typography>
      <Typography>
        <Typography variant='h6' component='span'>
          Feeds Created:
        </Typography>
        &nbsp;{feeds_created}
      </Typography>
      <Typography>
        <Typography variant='h6' component='span'>
          Feeds Shared:
        </Typography>
        &nbsp;{feeds_shared}
      </Typography>
    </Box>
  );
};

const PrivateIntegrationsTable = ({
  private_sources,
}: PrivateIntegrationsTablePropsType): JSX.Element => {
  const rows = private_sources.map((app) => ({
    id: app.first_linked,
    ...app,
  }));
  const columns = React.useMemo(() => PRIVATE_SOURCES_COLUMNS, []);
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <Typography variant='h5'>App Integrations:</Typography>
      <Box className={classes.tableWrapper}>
        <DataGrid rows={rows} columns={columns} autoPageSize />
      </Box>
    </Box>
  );
};

const PublicIntegrationsTable = ({
  public_sources,
}: PublicIntegratiobsTablePropsType): JSX.Element => {
  const rows = public_sources.map((source) => ({
    id: source.key,
    ...source,
  }));
  const columns = React.useMemo(() => PUBLIC_SOURCES_COLUMNS, []);
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <Typography variant='h5'>Public Integrations:</Typography>
      <Box className={classes.publicTableWrapper}>
        <DataGrid rows={rows} columns={columns} autoPageSize />
      </Box>
    </Box>
  );
};

const UserActivity = (): JSX.Element => {
  const classes = useStyles();
  const { organizationId, userId } = useParams<RouteParam>();
  const { isLoading, userActivity } = useFetchUserActivity(
    organizationId,
    userId
  );

  return (
    <Box className={classes.rootContainer}>
      <Typography variant='h4'>User Activity</Typography>
      {isLoading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UserDetails details={userActivity} />
          <PrivateIntegrationsTable
            private_sources={userActivity.private_apps}
          />
          <PublicIntegrationsTable public_sources={userActivity.public_apps} />
        </>
      )}
    </Box>
  );
};

export default UserActivity;
