import { useMutation } from 'react-query';

import { axiosInstance } from '../../axios';

export type updateOrganizationPayloadType = {
  id: number;
  allow_other_domain_users?: boolean;
  auto_add_domain_users?: boolean;
  allow_sharing_outside_org?: boolean;
};

export const useUpdateOrganization = (): any => {
  return useMutation(
    ({ id, ...requestBody }: updateOrganizationPayloadType) => {
      return axiosInstance.put(`/organizations/${id}`, requestBody);
    }
  );
};
